import {
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    TextField,
  } from "@material-ui/core";
  import axios from "axios";
  import { Fragment, useState } from "react";
  import toast from "react-hot-toast";
  import { uri } from "./help/Api";
  import { useStylesTD, useStylesTF } from "./St/comps/UseStyle";
  
  
  export default function LoginTest() {
    const [password, setPassword] = useState("");
    const [username, setUsername] = useState("");
    // useEffect(() => {
    //   console.log("the items is", sto);
    // });
    const clasTF = useStylesTF();
    const clasTD = useStylesTD();
    const checkConfirm = () => {
      axios
        .post(
          "https://localhost:44317/api/Account/Register" ,
            {
                Email:username,
                Password: password,
                ConfirmPassword: password,
            }
        )
        .then((response) => {
          console.log(response);
        })
        .catch((err) => {
          console.log(err);
          toast.error("لا يوجد لديك صلاحية للوصول");
        });
    };
    const getToken = () => {
        axios
          .get(
            "https://localhost:44317/token??"+
            `grant_type=password&&username=eds@gmail.com&&password=Eds1Eds2)`
          )
          .then((response) => {
            console.log(response);
          })
          .catch((err) => {
            console.log(err);
            toast.error("لا يوجد لديك صلاحية للوصول");
          });
      };
  
    return (
      <Fragment>
        <Container justifyContent="center">
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id={"username"}
              label={"اسم المستخدم"}
              className={clasTF.rootSale}
              InputProps={{ className: clasTD.input }}
              type="text"
              name={"username"}
              value={username}
              onChange={(e) => {
                setUsername(e.target.value);
              }}
            />
          </Container>
          <Container justifyContent="center">
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id={"password"}
              label={"كلمة المرور"}
              className={clasTF.rootSale}
              InputProps={{ className: clasTD.input }}
              type="password"
              name={"password"}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </Container>
 
          <Button variant="contained" onClick={checkConfirm}>
            دخول
          </Button>
          <Button variant="contained" onClick={getToken}>
            get token
          </Button>

      </Fragment>
     
    );
  }
  