import React, { useState, useEffect } from "react";
import { txtA, txtE } from "../../../Label/Lbl";
import axios from "axios";
import { uri } from "../../../help/Api";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
// import MaterialTable from "material-table";
import { insh } from "../../../Context/InshData";
// Ust
import { useInvCa, useInvMain } from "../../../St/Reviews/UseStyle";
// csG
// import {stElemnt} from '../../St/Reviews/cStyle'
import Paper from "@material-ui/core/Paper";

import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  itemW: {
    // width: '100%',
    fontSize: "0.6rem",
    //  fontWeight:'700',
    fontWeight: "bold",

    textAlign: "left",
  },
  table: {
    height: "15px",
    color: "#000",
    textAlign: "-webkit-center",
    margin: "-2px",
    width: "0px",
    padding: "15px 0px",
    margin: "0px",
    whiteSpace: "nowrap",
    border: "solid 0.1px",
    // backgroundColor: '#20369f',
    backgroundColor: "#d3d3d3 ",
    padding: "7px 1px",
    marginLeft: 1,
    marginRight: 1,
  },
  allWidth: {
    fontSize: "0.8rem",
    //  fontWeight:'700',
    fontWeight: "bold",
  },
  checStyl: {
    border: "solid 2px",
    // width: '100%',
    fontSize: "0.8rem",
    //  fontWeight:'700',
    fontWeight: "bold",
    textAlign: "center",
    marginLeft: "-0.2cm",
    marginRight: "-0.2cm",
  },
  checStyl11: {
    // border: 'solid 2px',
    // width: '100%',
    fontSize: "0.78rem",
    //  fontWeight:'700',
    fontWeight: "bold",
    textAlign: "center",
    marginLeft: "-0.2cm",
    marginRight: "-0.2cm",
  },
  checStyl2: {
    // width: '100%',
    fontSize: "0.7rem",
    //  fontWeight:'700',
    fontWeight: "bold",

    textAlign: "right",
    marginBottom: 2,
  },
  checStyl3: {
    border: "solid 1px",
    fontSize: "0.6rem",
    fontWeight: "bold",
    textAlign: "center",
    padding: "7px 1px",
  },
  checStyl4: {
    border: "solid 1px",

    fontSize: "0.5rem",
    //  fontWeight:'700',
    fontWeight: "bold",
    textAlign: "center",
    padding: "5px 1px",
  },
  checStyl5: {
    fontSize: "0.6rem",
    //  fontWeight:'700',
    fontWeight: "bold",
    textAlign: "center",
    padding: "3px 1px",
  },
  checStyl6: {
    border: "solid 1px",

    fontSize: "0.6rem",
    //  fontWeight:'700',
    fontWeight: "bold",
    textAlign: "center",
    padding: "3px 1px",
  },
}));

const Body = (props) => {
  const clasm = useInvMain();
  const clas = useInvCa();
  // const clasB = useInvBodyCa();
  const [AE, setAE] = useState(null);
  const { onUnmountComplete } = props;
  const classes = useStyles();

  const [titlee, setTitlee] = useState("");

  const title_inv = () => {
    if ((txtA.taxNumr = "")) {
      setTitlee("فاتورة ضريبية مبسطة");
    } else {
      setTitlee("فاتورة ضريبية ");
    }
  };

  const headerS = {
    height: "15px",
    color: "#000",
    textAlign: "-webkit-center",
    margin: "-2px",

    width: "0px",

    padding: "15px 0px",
    margin: "0px",
    whiteSpace: "nowrap",

    border: "solid 0.1px",
    // backgroundColor: '#20369f',
    backgroundColor: "#d3d3d3 ",
    padding: "7px 1px",
    marginLeft: 1,
    marginRight: 1,
  };
  const locInv = JSON.parse(localStorage.getItem("datainv")) || {};

  const [sta, setSta] = useState({ info: insh.RptInv2, tot: {}, StngIA: {} });
  // const [list, setList] = useState([]);
  const getInfo = async () => {
    const tb = {
      Rpt_Inv: true,
      SettInvsApp: true,
      parms: {
        lprocid: locInv.proc_id,
        ibranchid: locInv.branch,
        sProcTypeInvProc: locInv.ProcTypeInvProc,
        iFoundid: 1,
      },
      autos: {},
    };
    //  console.log('Rpt__tb'); console.log(tb)
    //  alert(`${uri}Res`)
    await axios
      .post(`${uri}Res`, tb)
      .then((res) => {
        console.log("res.data.RptInv");
        console.log(res.data);
        setSta({
          info: res.data.RptInv,
          tot: res.data.infoBill,
          StngIA: res.data.SettInvsApp,
        });

        // console.log('res.data.RptInv'); console.log(res.data);
      })
      .catch((error) => {});

    setAE(false);
  };

  useEffect(() => {
    setTimeout(async () => {
      await getInfo();
      onUnmountComplete();
    }, 0);
  }, []);

  const totalsTitleA_ = () => {
    return (
      <Grid item container className={clas.BdytotTitle}>
        <Grid item xs={6} className={clasm.minlft}>
          {txtA.totalAmount}
        </Grid>
        <Grid item xs={6} className={clasm.minrit}>
          {txtE.totalAmount}
        </Grid>
      </Grid>
    );
  };

  const totalsTitleE_ = () => {
    return (
      <Grid item container className={clas.BdytotTitle}>
        <Grid item xs={6} className={clasm.minlft}>
          {txtE.totalAmount}
        </Grid>
        <Grid item xs={6} className={clasm.minrit}>
          {txtA.totalAmount}
        </Grid>
      </Grid>
    );
  };

  const totalsContntA_ = () => {
    return (
      <Grid item container className={clas.BdytotSub}>
        <Grid item xs={8} className={clas.BdytotCntr}>
          <Grid item xs={12}>
            {sta.StngIA.amountWithVatA}
          </Grid>
          <Grid item xs={12}>
            {sta.StngIA.amountWithVatE}
          </Grid>
        </Grid>
        <Grid item xs={4} className={clas.BdytotCntrVal}>
          {sta.tot.amountWithVat?.toFixed(2)}
        </Grid>

        <Grid item xs={8} className={clas.BdytotCntr}>
          {sta.StngIA.totdiscountA + " " + sta.StngIA.totdiscountE}
        </Grid>
        <Grid item xs={4} className={clas.BdytotCntrVal}>
          {sta.tot.discount?.toFixed(2)}
        </Grid>

        <Grid item xs={8} className={clas.BdytotCntr}>
          <Grid item xs={12}>
            {sta.StngIA.amountWithVat2A}
          </Grid>
          <Grid item xs={12}>
            {sta.StngIA.amountWithVat2E}
          </Grid>
        </Grid>
        <Grid item xs={4} className={clas.BdytotCntrVal}>
          {sta.tot.amountWithVat?.toFixed(2)}
        </Grid>

        <Grid item xs={8} className={clas.BdytotCntr}>
          <Grid item xs={12}>
            {sta.StngIA.amountOfVatA}
          </Grid>
          <Grid item xs={12}>
            {sta.StngIA.amountOfVaEt}
          </Grid>
        </Grid>
        <Grid item xs={4} className={clas.BdytotCntrVal}>
          {sta.tot.amountOfVat?.toFixed(2)}
        </Grid>

        <Grid item xs={8} className={clas.BdytotCntr}>
          <Grid item xs={12}>
            {sta.StngIA.tot_netA}
          </Grid>
          <Grid item xs={12}>
            {sta.StngIA.tot_netE}
          </Grid>
        </Grid>
        <Grid item xs={4} className={clas.BdytotCntrVal}>
          {sta.tot.tot_net?.toFixed(2)}
        </Grid>
      </Grid>
    );
  };

  const totalsContntE_ = () => {
    return (
      <Grid item container className={classes.checStyl2}>
        <Grid item xs={4} className={classes.checStyl5}>
          {(sta.tot.tot_net - sta.tot.amountOfVat + sta.tot.discount)?.toFixed(
            2
          )}
        </Grid>
        <Grid item xs={8} className={classes.checStyl2}>
          {/* <Grid item xs={12}>{txtA.amountWithVat}</Grid> */}
          <Grid item className={classes.checStyl5} xs={12}>
            الاجمالي قبل الضريبة
          </Grid>
          {/* <Grid item xs={12}>{txtE.amountWithVat}</Grid> */}
        </Grid>

        <Grid item xs={4} className={classes.checStyl5}>
          {sta.tot.discount?.toFixed(2)}
        </Grid>
        <Grid item xs={8} className={classes.checStyl5}>
          {txtE.totdiscount + " " + txtA.totdiscount}
        </Grid>

        <Grid item xs={4} className={classes.checStyl5}>
          {(sta.tot.tot_net - sta.tot.amountOfVat)?.toFixed(2)}
        </Grid>
        <Grid item xs={8} className={classes.checStyl5}>
          <Grid item xs={12}>
            الاجمالي الخاضع للضريبة
          </Grid>
          {/* <Grid item xs={12}>{txtE.amountWithVat2}</Grid> */}
        </Grid>

        <Grid item xs={4} className={classes.checStyl5}>
          {sta.tot.amountOfVat?.toFixed(2)}
        </Grid>
        <Grid item xs={8} className={classes.checStyl5}>
          <Grid item className={classes.checStyl5} xs={12}>
            {txtA.amountOfVat}
          </Grid>
          {/* <Grid item xs={12}>{txtE.amountOfVat}</Grid> */}
        </Grid>

        <Grid item className={classes.checStyl6} xs={4}>
          {sta.tot.tot_net?.toFixed(2)}
        </Grid>
        <Grid item className={classes.checStyl6} xs={8}>
          <Grid item className={classes.checStyl5} xs={12}>
            الاجمالي يشمل القيمة المضافة
          </Grid>
          {/* <Grid item className={classes.checStyl5} xs={12}>{txtA.tot_net}</Grid> */}
          {/* <Grid item className={classes.checStyl5} xs={12}>{txtE.tot_net}</Grid> */}
        </Grid>
        <Grid item className={classes.checStyl6} xs={6}>
          {" متبقي"}
          {"\n"}
          {sta.tot?.inv_type_name == "نقدية"
            ? 0
            : sta?.tot?.tot_net?.toFixed(2) - sta?.tot?.paid }
        </Grid>
        <Grid item className={classes.checStyl6} xs={6}>
          {" مدفوع"}
          {"\n"}
          {sta?.tot?.inv_type_name == "نقدية"
            ? sta?.tot?.tot_net?.toFixed(2)
            : sta?.tot?.paid}
        </Grid>

        <Grid item className={classes.checStyl6} xs={5}>
          {" بعد"}
          {"\n"}
          {sta.tot?.cust_balance_new?.toFixed(2)}
        </Grid>
        <Grid item className={classes.checStyl6} xs={5}>
          {" قبل"}
          {"\n"}
          {sta.tot?.cust_balance_old?.toFixed(2)}
        </Grid>
        <Grid item className={classes.checStyl6} xs={2}>
          {"الرصيد "}
        </Grid>

      </Grid>
    );
  };

  return (
    <Grid item container className={clas.Bdyroot}>
      <Grid item container className={clas.BdyundrHeaderTF}>
        {locInv.ProcTypeInvProc != "03" && (
          <Grid item xs={12}>
            <Typography className={classes.checStyl11}>
              {sta.tot.tax_no_cust === ""
                ? "فاتورة ضريبية مبسطة"
                : "فاتورة ضريبية"}
            </Typography>
            {/* <Typography className={[clas.contnt,clasm.minclr]}>{"titlee"}</Typography> */}
          </Grid>
        )}

        <Grid item xs={12}>
          <Typography className={classes.checStyl}>
            {locInv.invN +
              " " +
              sta.tot.inv_type_name +
              " " +
              sta.tot.pay_type_name}
          </Typography>
        </Grid>
      </Grid>

      <Grid
        item
        container
        style={{ border: "solid 1px #000", marginBottom: 5, padding: 3 }}
      >
        <Grid item xs={3} className={classes.checStyl2}>
          <Paper>{sta.tot?.refno}</Paper>
        </Grid>
        <Grid xs={3} className={classes.checStyl2}>
          {"رقم المرجع"}
        </Grid>

        <Grid item xs={3} className={classes.checStyl2}>
          {sta.tot.id}
        </Grid>
        <Grid xs={3} className={classes.checStyl2}>
          {txtA.invNumr}
        </Grid>

        <Grid xs={4} className={classes.checStyl2}>
          {moment(sta.tot.date).format("hh:mm A")}
        </Grid>

        <Grid xs={4} className={classes.checStyl2}>
          {moment(sta.tot.date).format("YYYY/MM/DD")}
        </Grid>
        <Grid xs={4} className={classes.checStyl2}>
          {txtA.invDate}
        </Grid>

        {/* <Grid xs={6} className={classes.checStyl2}>{txtA.time}</Grid> */}

        <Grid xs={8} className={classes.checStyl2}>
          <Paper>{sta.tot.cust_name}</Paper>
        </Grid>
        <Grid xs={4} className={classes.checStyl2}>
          {txtA.custmName}
        </Grid>
        <Grid xs={8} className={classes.checStyl2}>
          {sta?.tot?.cust_address + " - " + sta?.tot?.cust_mobile}
        </Grid>
        <Grid xs={4} className={classes.checStyl2}>
          {"بيانات العميل"}
        </Grid>
        <Grid xs={6}>
          <Paper className={classes.checStyl2}>{sta.tot.tax_no_cust}</Paper>
        </Grid>
        <Grid xs={6} className={classes.checStyl2}>
          {txtA.taxNumr}
        </Grid>

      </Grid>

      <Grid item container>
        <Grid item className={classes.checStyl3} xs={3}>
          الاجمالي
        </Grid>
        <Grid item className={classes.checStyl3} xs={2}>
          السعر
        </Grid>
        <Grid item className={classes.checStyl3} xs={1}>
          الوحده
        </Grid>
        <Grid item className={classes.checStyl3} xs={1}>
          الكمية
        </Grid>
        <Grid item className={classes.checStyl3} xs={4}>
          اسم الصنف
        </Grid>
        <Grid item className={classes.checStyl3} xs={1}>
          #
        </Grid>
      </Grid>

      <Grid item container>
        {sta?.info?.map((item, i) => (
          <Grid item container key={i}>
            <Grid item className={classes.checStyl4} xs={3}>
              {item.tot_net_curr}
            </Grid>
            <Grid item className={classes.checStyl4} xs={2}>
              {item.new_exchange_price}
            </Grid>
            <Grid item className={classes.checStyl4} xs={1}>
              {item.unit_name}
            </Grid>
            <Grid item className={classes.checStyl4} xs={1}>
              {item.val1}
            </Grid>
            <Grid item className={classes.checStyl4} xs={4}>
              {item.symbol}
            </Grid>
            <Grid item className={classes.checStyl4} xs={1}>
              {i + 1}
            </Grid>
          </Grid>
        ))}
      </Grid>

      {/* {AE ? totalsTitleA_() : totalsTitleE_()} */}
      {AE ? totalsContntA_() : totalsContntE_()}
    </Grid>
  );
};
export default Body;
