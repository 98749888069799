import axios from "axios";
import _ from "lodash";
import moment from "moment";
import { tbReGitData } from "../Context/InshPublic";
import { uri } from "../help/Api";
import * as yup from "yup";

export const SyncLocalStorage = async () => {
  await axios
    .post(uri + "Res", tbReGitData)
    .then((res) => {
      localStorage.setItem("sto", JSON.stringify(res.data));
    })
    .catch((error) => {
      //  console.log('nooooo sttorgeing');
      alert("لم يتم التحديث");
    });
};
export const payTypes = [
  { id: 0, name: "عام" },
  { id: 1, name: "آجل" },
  { id: 2, name: "نقدي" },
  { id: 3, name: "شبكة" },
  { id: 4, name: "بنك" },
  { id: 5, name: "موظف" },
];

export const defaultCustomer = {
  name: "",
  tax_no: "",
  mobile: "",
  cust_DiscValue: 0,

  cust_PresValue: 0,
  maxdepit: 0,
  IValue: 0,
  national_id: "",
  car_number: "",
  car_shace: "",
  address: "",
  area: "",
  ity: -1,
  country: "",
  city: "",
  email: "",
  fax: "",
  notes: "",
  tel: "",
  Cust_inv_type: payTypes[0],
  salesmen: "",
  acc_no: "",
  //relationShip
  CustandSup: 1,
  act: "",
  res_id: "",
  desc3: "",
  // defult
  type: 1,
};
export const defaultEmployee = {
  id: 0,
  name: "",
  manag: "",
  dep: "",
  job: "",
  birth_date: moment(new Date()).format("YYYY-MM-DD"),
  work_date: moment(new Date()).format("YYYY-MM-DD"),
  nationality: "",
  insurance_no: 0,
  state: { id: 1, name: "نشط" },
  mobile: "",
  branches: [],
  sex: "M",
  address: "",
  email: "",
  fax: "",
  notes: "",
  tel: "",
  acc_no: "",
  salary_basic: 0,
  salary_add: 0,
  salary_other: 0,
  house: 0,
  food: 0,
  travel: 0,
  medical: 0,
  general_email_Sender: 0,
  parentcode: 253,
  marital_state: -1,
};
export const EmployeeValidateSchema = yup.object().shape({
  name: yup.string().required("يجب اسم الموظف"),
  manag: yup.string().required("يجب اختيار الإدارة "),
  dep: yup.string().required("يجب اختيار القسم "),
  branches: yup.array().min(1, "قم بتحديد فرع للموظف"),
});
export const convertGroupsToTreeData = (pData) => {
  const res = [];
  for (let group of pData) {
    if (group.i == 0) {
      res.push(group);
    } else {
      const parent = _.find(pData, { id: parseInt(group.i) });
      if (!parent?.hasOwnProperty("children"))
        _.assign(parent, { children: [] });
      parent.children.push(group);
    }
    _.assign(group, { label: group.name, value: group.id });
  }
  return res;
};

export function getReFormatingAccount(data) {
  const newData = data.map((row, i) => {
    return {
      id: row.Code,
      name: row.AName,
    };
  });
  return newData;
}
export function getReFormatingAccountOmla(data) {
  const newData = data.map((row, i) => {
    return {
      id: row.Code,
      name: row.AName,
      acc_omlat: row?.omlat?.map((om) => {
        return om.omla_id;
      }),
    };
  });
  return newData;
}

export function GetItemFromOptions(id, options) {
  const index = options?.findIndex((op) => op.id == id);
  return options[index];
}
