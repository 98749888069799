import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import { uri } from "../../../../help/Api";

import LoadingPage from "../../../../services/LoadingPage";
// import Footer from "./Footer";
import * as _ from "lodash";
import { useLocation } from "react-router-dom";
import { sto } from "../../../../Context/InshData";
import HeaderA from "./HeaderA";
import BodyA from "./BodyA";
import FooterA from "./FooterA";
import { Typography } from "@material-ui/core";
import { stElemnt } from "../../../../St/Reviews/cStyle";

function OnePage(props) {
  const { found, chunk } = props;
  return (
    <Typography component="div" style={stElemnt.AF.Reviws.Typogmain}>
      <Typography component="div" style={{ ...stElemnt.AF.Reviws.Typogsub }}>
        <HeaderA found={found} />
        <BodyA data={chunk ?? []} />
        <FooterA found={found} />
      </Typography>
    </Typography>
  );
}
function RestPrintContainer(props) {
  const iInfo = JSON.parse(localStorage.getItem("iInfos")) || {};
  const [found, setFound] = useState({});
  const [loading, setLoading] = useState(true);

  const [chunks, setChunks] = useState(_.chunk(iInfo.data, 10));
  const getInfo = async () => {
    axios
      .get(uri + `Foundation?branch=${sto.branchi}`)
      .then((res) => {
        setFound(res.data);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    setTimeout(async () => {
      await getInfo();
      setLoading(false);
      // onUnmountComplete();
    }, 0);
  }, []);

  if (!found || loading) return <LoadingPage />;

  return (
    <Fragment>
      {chunks.map((page, i) => {
        return <OnePage {...{ found, chunk: page }} />;
      })}
    </Fragment>
  );
}
export default RestPrintContainer;
