import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { useStylesTF } from "../../../../../St/comps/UseStyle";
import AccountSelect from "../../../../../components/controls/accounts-select";
import { useEffect } from "react";
import { Button, colors } from "@material-ui/core";
import GlobleOmlatForm from "../../../../../components/controls/OmlatForm";
import SaveeIcon from "@material-ui/icons/Save";
import { subMultiReceiptSchema } from "../../../../../services/multi-receipt-services";
import toast from "react-hot-toast";
import { getAccountOmlat } from "../../../../../services/omlat-services";
import { useTranslation } from "react-i18next";
import { subRestrictionValidateSchema } from "../../Restrictions/restriction-validate-schema";
import { getAmountNumber } from "../../../../../utils/FormatNumbers";
import { Autocomplete } from "@material-ui/lab";

export default function ORestFormSub(props) {
  const {
    accounts,
    editing,
    cancelUpdate,
    currentRow,
    setCurrentRow,
    addRow,
    updateRow,
    AutoOptions,
  } = props;
  const { t } = useTranslation(["forms"]);

  const clasTF = useStylesTF();
  useEffect(() => {
    console.log("the effect ", accounts);
  });

  // const submit = () => {
  //   subMultiReceiptSchema
  //     .validate(currentRow, { abortEarly: false })
  //     .then(function (valid) {
  //       if (editing) updateRow(currentRow.id, currentRow);
  //       else addRow(currentRow);
  //     })
  //     .catch((error) => {
  //       toast.error(error.errors[0]);
  //     });
  // };

  const submit = () => {
    subRestrictionValidateSchema
      .validate(currentRow, { abortEarly: false })
      .then(function (valid) {
        if (editing) updateRow(currentRow.id, currentRow);
        else
          addRow({
            ...currentRow,
            dept: getAmountNumber(currentRow.dept),
            credit: getAmountNumber(currentRow.credit),
          });
        // addSubRestrictionFunc({
        //   ...data,
        //   dept: getAmountNumber(data.dept),
        //   credit: getAmountNumber(data.credit),
        // });

        // // clear textBox
        // setData(defaultData);
        // setValueCostcenter(optionsCostcenter[0]);
        // setSubmitting(false);
      })
      .catch(function (err) {
        toast.error(err.errors[0]);
        // setSubmitting(true);
        console.log(err.errors);
      });
  };
  const changeAccountHandle = async (newValue) => {
    const accOmlat = getAccountOmlat(newValue.acc_omlat, AutoOptions.omlats);
    let nObject = {
      ...currentRow,
      account: newValue,
      acc_no: newValue?.id,
      name: newValue?.name,
    };
    setCurrentRow(nObject);
  };

  return (
    <React.Fragment>
      <Grid container style={{ padding: 10 }} spacing={2}>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <AccountSelect
            accounts={accounts}
            account={currentRow?.account}
            onSelect={(newValue) => {
              changeAccountHandle(newValue);
            }}
          />
        </Grid>
        <Grid item lg={2} md={2} sm={3} xs={6}>
          <TextField
            type="number"
            fullWidth
            variant="outlined"
            size="small"
            id="dept"
            label={"مدين"}
            className={clasTF.rootEDS}
            style={{ backgroundColor: colors.grey[50] }}
            InputProps={{ className: clasTF.input }}
            name="dept"
            // error={submitting && data.credit ===''}
            disabled={currentRow.credit != "" && currentRow.credit != 0}
            value={currentRow?.dept}
            onChange={(e) => {
              const ex_value = currentRow?.acc_omla?.exchange;
              console.log(
                "the new value is",
                Number(currentRow?.credit + currentRow?.dept)
              );
              let nObject = {
                ...currentRow,
                dept: e.target.value,
                dept_loc: e.target.value * ex_value,
              };
              setCurrentRow(nObject);
            }}
          />
        </Grid>
        <Grid item lg={2} md={2} sm={3} xs={6}>
          <TextField
            type="number"
            fullWidth
            variant="outlined"
            size="small"
            id="val"
            label={"دائن"}
            className={clasTF.rootEDS}
            style={{ backgroundColor: colors.grey[50] }}
            InputProps={{ className: clasTF.input }}
            name="value"
            disabled={currentRow.dept != "" && currentRow.dept != 0}
            value={currentRow?.credit}
            onChange={(e) => {
              const ex_value = currentRow?.acc_omla?.exchange;
              let nObject = {
                ...currentRow,
                credit: e.target.value,
                credit_loc: e.target.value * ex_value,
              };
              setCurrentRow(nObject);
            }}
          />
        </Grid>
        <Grid item lg={2} md={2} sm={3} xs={6}>
          <Autocomplete
            value={currentRow?.center ?? null}
            onChange={(event, newValue) => {
              setCurrentRow({ ...currentRow, center: newValue });
            }}
            id="controllable-states-demo"
            size="small"
            options={AutoOptions.costcenters}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name
            }
            // style={{  }}
            disableClearable
            renderInput={(params) => (
              <TextField
                id="valueCostcenter"
                {...params}
                label={t("center")}
                variant="outlined"
                size="small"
                // InputLabelProps={{ className: claslbl.clor }}
              />
            )}
          />
        </Grid>

        <Grid item lg={6} md={6} sm={6} xs={12}>
          <GlobleOmlatForm
            {...{
              omlat: currentRow?.acc_omlat,
              omla: currentRow?.acc_omla,
              amount: Number(
                Number(currentRow?.credit) + Number(currentRow?.dept)
              ),
              disabled: false,
              onExchangeChange: (value) => {
                let newcurrentRow = {
                  ...currentRow,
                  acc_omla: { ...currentRow?.acc_omla, exchange: value },
                  credit_loc: currentRow.credit * value,
                  dept_loc: currentRow.dept * value,
                };
                setCurrentRow(newcurrentRow);
              },
              onChange: (newValue) => {
                console.log(
                  "the new value is",
                  Number(currentRow?.credit + currentRow?.dept)
                );

                let newcurrentRow = {
                  ...currentRow,
                  acc_omla: newValue,
                  credit_loc: currentRow.credit * newValue.exchange,
                  dept_loc: currentRow.dept * newValue.exchange,
                };
                setCurrentRow(newcurrentRow);
              },
            }}
          />
        </Grid>

        <Grid item lg={4} md={4} sm={8} xs={8}>
          <TextField
            type="text"
            fullWidth
            variant="outlined"
            size="small"
            id="note"
            label={t("note")}
            className={clasTF.rootEDS}
            InputProps={{ className: clasTF.input }}
            onChange={(e) =>
              setCurrentRow({ ...currentRow, notes: e.target.value })
            }
            name="note"
            value={currentRow?.note}
          />
        </Grid>

        <Grid item lg={1} md={2} sm={2} xs={6}>
          <Button
            style={{ direction: "ltr", backgroundColor: colors.grey[700] }}
            variant="contained"
            color="primary"
            fontSize="large"
            size="medium"
            onClick={submit}
            startIcon={<SaveeIcon />}
          >
            {editing ? t("save-update") : t("download")}{" "}
          </Button>
        </Grid>
        <Grid item lg={1} md={1} sm={2} xs={4}>
          {editing && (
            <Button
              style={{ direction: "ltr", backgroundColor: colors.red[400] }}
              variant="contained"
              color="primary"
              fontSize="large"
              size="medium"
              onClick={() => {
                cancelUpdate();
              }}
            >
              {t("cancel")}{" "}
            </Button>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
