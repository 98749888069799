import React from "react";
import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";
// Ust
import { useInvAF } from "../../../../St/Reviews/UseStyle";
// csG
import { stElemnt } from "../../../../St/Reviews/cStyle";

import { colors, Container, makeStyles, Typography } from "@material-ui/core";
import { useBody } from "../../bill-template/style";
import { txtA, txtE } from "../../../../Label/Lbl";
import  InvTotal  from "./InvTotal";

const BodyInv = (props) => {
  const { onUnmountComplete, data } = props;
  const clas = useInvAF();
  const calssBody = useBody();
  const islast=(data?.length )
  const cellS = {
    padding: "6.9px 2px",
    textAlign: "-webkit-center",
    margin: "0px",
    whiteSpace: "normal",
    // textAlign: '-webkit-right',
    border: "solid 0.1px",
    // borderRight: 'solid 0px',
    width: "200px",
    // color: '#000',
    fontFamily: "Amiri-Regular",
    // border:'solid 1px red',
  };
  var columns = [
    { title: "م", field: "id" },
    { title: "الباركود", field: "barcode" },
    { title: txtA.itemName, field: "symbol", cellStyle: cellS },
    {
      title: txtA.unitPrice,
      field: "new_exchange_price",
      cellStyle: stElemnt.AF.cell,
    },
    { title: txtA.Quantity, field: "val1" },
    { title: txtA.taxableAmount, field: "totBill" },
    { title: txtA.discount, field: "discount" },
    // { title: txtA.taxRate, field: "taxperc" },
    { title: txtA.taxAmount, field: "taxval" },
    { title: txtA.subTotalWithVat, field: "tot_net_curr" },
  ];

  const data2 = data.map((row, i) => {
    return { ...row, id: i + 1 };
  });
  return (
    <Container>
      <Grid
        item
        container
        className={clas.Bdyroot}
        style={{ direction: "rtl" }}
      >
        <Grid item container>
          <Grid item xs={12} className={calssBody.BdymtTabl}>
            <MaterialTable
              style={{ boxShadow: "none" }}
              //style={{direction:'ltr'}}
              options={{
                showTitle: false,
                toolbar: false,
                sorting: false,
                selection: false,
                // ToolBar Search
                searchFieldAlignment: "left",
                searchFieldVariant: "outlined",
                // ToolBar header
                showSelectAllCheckbox: false,
                showTextRowsSelected: false,
                // body
                // rowStyle:(data,i)=>i%2!=0?{background:'#038b0321'}:null,
                // footer
                pageSize: 5,
                paginationType: "stepped",
                showFirstLastPageButtons: false,

                paging: false,
                headerStyle: {
                  ...stElemnt.AF.header,
                  backgroundColor: colors.grey[100],
                  fontWeight: "bold",
                  padding: "1px 1px",
                  border: "solid 0px",
                  borderBottom: "solid 0.1px",
                  borderRight: "solid 0.1px",
                },
                cellStyle: { ...stElemnt.AF.cell, fontWeight: "bold" },
              }}
              localization={{ body: { emptyDataSourceMessage: <h1></h1> } }}
              columns={columns}
              data={data2}
            />
             {islast && <InvTotal/>}
          </Grid>
        </Grid>

        {/* <Grid container>
</Grid> */}
      </Grid>
    </Container>
  );
};

export default BodyInv;
